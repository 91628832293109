import { Skeleton } from './ui/skeleton'

import { Event } from '@src/api/types'
import { formatDate } from '@src/lib/utils'
import { PurchaseButton } from './ppv-purchase-button'

export interface PPVCardProps {
  isPreview: undefined | boolean
  event: Event
  eventDate: string
  eventDescription: string
  eventLogo: string
  eventButtonCopy: string
  showTicketButton: boolean
  showPurchaseButton?: boolean
  ticketLink: string
  ticketCopy: string
}

export function PPVCard({
  isPreview,
  event,
  eventDate,
  eventDescription,
  eventLogo,
  eventButtonCopy,
  showTicketButton,
  showPurchaseButton = true,
  ticketLink,
  ticketCopy
}: PPVCardProps) {
  const isVideoSeries = event?.type === 'barstool_tv_vod_series'
  return (
    <div
      id='ppv-card'
      className='text-white w-full lg:max-w-[520px] lg:w-[43%] flex flex-col lg:pt-16'
    >
      {eventLogo ? (
        <img src={eventLogo} alt='event-logo' className='w-7/12 lg:w-auto h-auto max-h-[224px]' />
      ) : (
        <Skeleton className='h-[75px]' />
      )}
      {!isVideoSeries && (
        <>
          {eventDate ? (
            <h3 className='my-6 text-xl font-bold leading-tight md:my-8'>
              {formatDate(eventDate, 'LLLL d, h:mma', {
                zone: 'America/New_York'
              })}{' '}
              ET
            </h3>
          ) : (
            <Skeleton className='w-1/2 h-6 my-6 md:my-8' />
          )}
        </>
      )}
      {isVideoSeries && (
        <h3 className='my-6 text-xl font-bold leading-tight md:my-8'>Now Streaming</h3>
      )}
      {eventDescription ? (
        <div
          className='order-last text-sm lg:order-none'
          dangerouslySetInnerHTML={{
            __html: eventDescription
          }}
        />
      ) : (
        <Skeleton className='h-24' />
      )}
      <div className='flex flex-col items-center mb-6 space-y-4 text-sm lg:mt-8 sm:flex-row sm:space-x-6 sm:space-y-0 lg:mb-0'>
        {showPurchaseButton && (
          <PurchaseButton
            isPreview={isPreview}
            playLatestEpisode
            event={event}
            className='sm:w-3/5'
          >
            {eventButtonCopy}
          </PurchaseButton>
        )}
        {showTicketButton && ticketLink && (
          <>
            <a
              target='_blank'
              rel='noopener noreferrer'
              href={ticketLink}
              className='will-change-colors transition-colors w-full py-[10px] font-sans text-[13.5px] font-bold text-center text-white bg-transparent border-2 border-white rounded sm:w-2/5 hover:bg-[#282828]'
            >
              {ticketCopy}
            </a>
          </>
        )}
      </div>
    </div>
  )
}
