import React, { useState, useMemo } from 'react'
import { DateTime, DurationObjectUnits } from 'luxon'

import { getDateTimeInterval } from '@lib/utils'
import { useInterval } from '@hooks/interval'
import classNames from 'classnames'

export interface CountdownProps {
  toISO: string
  onTimeReached: () => void
  timerCopy?: string
  className?: string
}

export default function Countdown({ toISO, onTimeReached, timerCopy, className }: CountdownProps) {
  const futureDateTime = useMemo<DateTime>(() => DateTime.fromISO(toISO), [toISO])
  const [timeInterval, setTimeInterval] = useState<DurationObjectUnits>(
    getDateTimeInterval(futureDateTime)
  )

  useInterval(
    () => {
      setTimeInterval(getDateTimeInterval(futureDateTime))
    },
    1000,
    [setTimeInterval, futureDateTime]
  )

  const days = timeInterval.days ?? 0
  const hours = timeInterval.hours ?? 0
  const minutes = timeInterval.minutes ?? 0
  const seconds = timeInterval.seconds ?? 0

  const hoursString = `${(hours ?? 0) < 10 ? '0' : ''}${hours}`
  const minutesString = `${(minutes ?? 0) < 10 ? '0' : ''}${minutes}`
  const secondsFixed = Number(seconds.toFixed(0))
  const secondsString =
    secondsFixed === 60 ? '00' : `${secondsFixed < 10 ? '0' : ''}${secondsFixed}`

  const countdownReached = days <= 0 && hours <= 0 && minutes <= 0 && seconds <= 0
  if (countdownReached) onTimeReached?.()

  return (
    <div
      data-testid='countdown'
      className={classNames(
        'text-white z-0 py-4 px-6 border-t border-b text-center font-sans font-medium gradient-5',
        className
      )}
    >
      <h1 className='mb-3 font-sans text-sm font-normal uppercase'>
        {timerCopy || 'Event Starts In'}
      </h1>
      <div className='flex flex-row items-start justify-center'>
        <div className='flex flex-col w-auto'>
          <div data-testid='days-count' className='text-[28px] leading-tight'>
            {days}
          </div>
          <div className='text-[11px] font-normal'>Days</div>
        </div>
        <div className='mx-[10px] mt-1 text-xl leading-tight'>:</div>
        <div className='flex flex-col w-auto'>
          <div data-testid='hours-count' className='text-[28px] leading-tight'>
            {hoursString}
          </div>
          <div className='text-[11px] font-normal'>Hours</div>
        </div>
        <div className='mx-[10px] mt-1 text-xl leading-tight'>:</div>
        <div className='flex flex-col w-auto'>
          <div data-testid='minutes-count' className='text-[28px] leading-tight'>
            {minutesString}
          </div>
          <div className='text-[11px] font-normal'>Mins</div>
        </div>
        <div className='mx-[10px] mt-1 text-xl leading-tight'>:</div>
        <div className='flex flex-col w-auto'>
          <div data-testid='seconds-count' className='text-[28px] leading-tight'>
            {secondsString}
          </div>
          <div className='text-[11px] font-normal'>Sec</div>
        </div>
      </div>
    </div>
  )
}
