import { useCallback, useState } from 'react'

import { Event } from '@api/types'
import { PurchaseButton } from '@src/components/ppv-purchase-button'
import { PPVCard } from '@src/components/ppv-card'
import { isDatePassed } from '@src/lib/utils'
import Countdown from './ui/countdown'
import VideoPlayer from './video-player'

const FOOTER_ITEMS: { image: string; caption: string }[] = [
  { image: '/static/images/ppv-streaming.png', caption: 'Reliable, High Quality Streaming' },
  { image: '/static/images/ppv-shield.png', caption: 'Safe & Secure Payment' },
  { image: '/static/images/ppv-ticket.png', caption: 'No Subscriptions Required' }
]

function FooterItem({ image, caption }: { image: string; caption: string }) {
  return (
    <div className='flex items-center mb-6 last-of-type:mb-0 lg:mb-0 lg:flex-col lg:w-1/3'>
      <img
        src={image}
        alt='PPV Streaming'
        className='w-8 h-8 mr-4 lg:mr-0 sm:w-16 sm:h-16 lg:mb-6'
      />
      <p className='lg:text-center'>{caption}</p>
    </div>
  )
}

function PPVLandingPage({
  event,
  isPreview,
  showPurchaseButton = true
}: {
  event: Event
  isPreview?: boolean
  showPurchaseButton?: boolean
}) {
  const { date, metadata } = event as Event

  const [countdownPassed, setCountdownPassed] = useState(isDatePassed(date))

  const handleCountdownReached = useCallback(() => {
    if (!countdownPassed) setCountdownPassed(true)
  }, [countdownPassed])

  return (
    <div className='flex flex-col items-center justify-center bg-[#121212] md:mt-[56px]'>
      <section className='relative w-full lg:mb-10'>
        <div className='relative mb-0 lg:float-right lg:min-w-3/4'>
          <img
            className='w-full lg:float-right lg:w-auto max-h-[600px]'
            alt='event-promo-image'
            src={metadata?.promo_poster_url}
          />
          <div className='absolute w-full h-20 -bottom-1 gradient-3' />
          <div className='absolute hidden w-2/5 h-full -left-1 3xl:block gradient-4' />
        </div>
        <div className='absolute hidden w-2/3 h-full lg:block bg-ppv-card-graident 3xl:hidden' />
        <div className='z-[2] inset-0 flex items-center relative justify-center w-full h-full px-4 mx-auto -mt-3 lg:mt-0 lg:px-8 lg:justify-start max-w-7xl lg:absolute'>
          <PPVCard
            isPreview={isPreview}
            event={event}
            eventLogo={metadata?.logo_url}
            eventDate={date}
            eventDescription={metadata?.cta?.body}
            eventButtonCopy={metadata?.cta?.headline}
            showTicketButton={metadata?.cta_ticket_link?.active}
            ticketLink={metadata?.cta_ticket_link?.destination}
            showPurchaseButton={showPurchaseButton}
            ticketCopy={metadata?.cta_ticket_link?.label}
          />
        </div>
      </section>
      {countdownPassed || !metadata.countdown_active ? (
        <div className='h-10 lg:h-20' />
      ) : (
        <Countdown
          timerCopy={metadata?.cta?.list}
          className='w-full max-w-3xl my-10 lg:mt-12 lg:mb-20'
          toISO={date}
          onTimeReached={handleCountdownReached}
        />
      )}
      <div className='w-full max-w-7xl'>
        {metadata?.faq_active && (
          <section className='flex flex-col items-start justify-between mb-10 lg:mb-20 lg:flex-row md:px-8'>
            {metadata?.promo_media_type === 'image' ? (
              <img
                className='w-full lg:w-1/2'
                src={metadata?.supporting_copy?.image}
                alt='preview'
              />
            ) : (
              <div className='w-full lg:w-1/2'>
                <VideoPlayer
                  id={1}
                  name='countdown_trailer'
                  status='ppv'
                  thumbnail={metadata.promo_video.thumbnail}
                  source={metadata.promo_video?.source?.primary_source}
                  videoId={'300'}
                />
              </div>
            )}
            <div className='w-full pt-6 space-y-6 text-white lg:w-[43%] px-4 sm:px-8 md:px-0 lg:py-0'>
              <h2 className='font-sans text-2xl font-bold'>
                {metadata?.supporting_copy?.headline}
              </h2>
              <div
                className='text-sm text-[#F1F1F1]'
                dangerouslySetInnerHTML={{
                  __html: metadata?.supporting_copy?.body
                }}
              />
              {showPurchaseButton && (
                <PurchaseButton
                  event={event}
                  isPreview={isPreview}
                  className='w-full px-8 md:min-w-[150px] md:w-auto md:mx-auto'
                  playLatestEpisode
                  disablePromoCode
                >
                  {metadata?.cta?.headline}
                </PurchaseButton>
              )}
            </div>
          </section>
        )}
      </div>
      <section className='flex flex-col items-center w-full py-10 text-white bg-black lg:py-16'>
        <h2 className='w-3/4 mb-6 font-sans text-base font-bold text-center sm:text-xl lg:font-normal sm:mb-14'>
          Barstool TV is the best option for streaming premium Barstool Sports content
        </h2>
        <div className='flex flex-col items-start max-w-4xl mx-auto text-white lg:flex-row lg:w-full'>
          {FOOTER_ITEMS.map((item, index) => (
            <FooterItem key={index} {...item} />
          ))}
        </div>
      </section>
    </div>
  )
}

export default PPVLandingPage
